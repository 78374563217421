import AlertPopup from '../AlertPopup/AlertPopup'
import React, { useState } from 'react'
import { useDetectSamsungDarkMode } from '../../hooks/detectSamsungDarkMode'
import SessionStorageManager from '../../services/SessionStorageManager'
import { isClient } from 'utils/runtimeUtils'
import { useTranslation } from 'react-i18next'

const DISMISS_DARK_MODE_WARNING_KEY = 'dismissedDarkModeWarning'

export function DarkModeWarningPopUp() {
  const { t } = useTranslation()
  const [userDismissedDarkModeWarning, setUserDismissedDarkModeWarning] =
    useState(() =>
      isClient()
        ? !!SessionStorageManager.get(DISMISS_DARK_MODE_WARNING_KEY)
        : false
    )
  const isDarkMode = useDetectSamsungDarkMode()

  return (
    <AlertPopup
      open={isDarkMode && !userDismissedDarkModeWarning}
      onClick={() => {
        setUserDismissedDarkModeWarning(true)
        SessionStorageManager.set(DISMISS_DARK_MODE_WARNING_KEY, true)
      }}
      title={t('general.errors.dark_mode_title')}
      subtitle={t('general.errors.dark_mode_subTitle')}
      buttonText={`${t('Ok')}!`}
    />
  )
}
