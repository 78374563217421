import { useEffect, useState } from 'react'
import { analytics, EVENT_NAMES } from '../services/Analytics/Analytics'

// https://stackoverflow.com/questions/58646758/how-to-detect-darkmode-on-samsung-internet-browser/78808834#78808834

async function checkIsDarkMode(): Promise<boolean> {
  return new Promise((resolve) => {
    if (
      typeof window !== 'undefined' &&
      window.navigator.userAgent.match(/Samsung/i)
    ) {
      const img = new Image()
      img.onload = () => {
        const ctx = document.createElement('canvas').getContext('2d')
        if (!ctx) {
          resolve(false)
          return
        }
        ctx.drawImage(img, 0, 0)
        const [r, g, b] = ctx.getImageData(0, 0, 1, 1).data
        // eslint-disable-next-line no-bitwise
        const isDarkMode = (r & b & g) < 255
        if (isDarkMode) {
          analytics.setUserProperties({
            'Samsung Dark Mode': true,
          })

          analytics.track(EVENT_NAMES.DARK_MODE_DETECTED, {})
        }
        resolve(isDarkMode)
      }
      img.src =
        'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMSIgaGVpZ2h0PSIxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxyZWN0IHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9IndoaXRlIi8+PC9zdmc+'
    } else {
      resolve(false)
    }
  })
}

// Since in Samsung Internet they force their dark mode design upon us we want to alert the user in those cases
// that the colors might be different, and prompt him to switch to light mode
export function useDetectSamsungDarkMode() {
  const [darkMode, setDarkMode] = useState(false)
  useEffect(() => {
    checkIsDarkMode().then((isDarkMode) => setDarkMode(isDarkMode))
  }, [])

  return darkMode
}
