import StorageManager from '../../services/StorageManager'

const PENDING_COUPON_DIALOG_KEY = 'pendingCouponDialog'

export const getPendingCouponDialog = () => {
  return StorageManager.get(PENDING_COUPON_DIALOG_KEY)
}

export const setPendingCouponDialog = (coupon) => {
  StorageManager.set(PENDING_COUPON_DIALOG_KEY, coupon)
}

export const removePendingCouponDialog = () => {
  StorageManager.remove(PENDING_COUPON_DIALOG_KEY)
}
